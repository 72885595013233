import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { VisitorEntities } from 'client/data/models/visitor';
import { getEvRangeSource, getUsedRange } from 'site-modules/shared/utils/core-page/ev-insights';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Link } from 'site-modules/shared/components/link/link';
import { RangeInsightMap } from './range-insight-map';

export function RangeInsight({
  vehicleName,
  modelName,
  year,
  testedRange,
  epaRange,
  reviewEstimatedRange,
  visitorLocation,
  onTooltipOpen,
  isUsed,
  isPluginHybrid,
}) {
  const containerRef = useRef();
  const [isRangeVisible, disconnect] = useOnScreen(containerRef);

  const totalRange = testedRange || epaRange || reviewEstimatedRange || 0;
  const currentRange = getUsedRange({ totalRange, year, isUsed });

  useEffect(() => {
    if (isRangeVisible && currentRange) {
      disconnect();

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'ev_insights_ev_range',
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.SELECT_CHANGE,
          creative_id: 'edm-entry-range',
          value: `${getEvRangeSource({
            isTested: !!testedRange,
            isEpa: !!epaRange,
            isEstimated: !!reviewEstimatedRange,
          })},${currentRange}`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRangeVisible, currentRange]);

  return (
    <div className="range-insight" ref={containerRef}>
      <div
        className={classnames({
          'heading-4 text-center': currentRange,
          'mb-0_25': currentRange && !isUsed,
          'mb-1': currentRange && isUsed,
        })}
      >
        {currentRange ? (
          <Fragment>
            {currentRange} miles
            {isUsed && (
              <TooltipItem
                className="top-0"
                item={{
                  placement: 'bottom',
                  text: (
                    <Fragment>
                      The range for a used {vehicleName} is estimated to be {currentRange} miles because electric cars
                      typically experience 1-2% of range loss per year, with slightly faster degradation over the first
                      50,000 miles as the car settles into its long term state, according to{' '}
                      <Link
                        className="text-underline"
                        to="https://www.recurrentauto.com/research/lessons-in-electric-car-battery-health"
                        target="_blank"
                        rel="nofollow"
                      >
                        Recurrent
                      </Link>
                      &apos;s study of 15,000 EVs.
                    </Fragment>
                  ),
                }}
                delay={{ show: 0, hide: 250 }}
                autohide={false}
                id="range-estimated"
                onTooltipOpen={onTooltipOpen}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>{isUsed ? 'Not available' : 'EPA Estimated Range not available'}</Fragment>
        )}
      </div>
      {!!currentRange && (
        <div className="medium text-center mb-1_5">
          {!isUsed && (
            <div className="mb-1">
              {testedRange ? (
                <Fragment>
                  Edmunds Tested Range
                  <TooltipItem
                    className="top-0 small"
                    item={{
                      placement: 'bottom',
                      text:
                        'Edmunds begins with a full battery charge and drives the vehicle on a mix of city and highway roads (approximately 60% city, 40% highway) until the battery is almost entirely empty. (We target 10 miles of remaining range for safety.) The miles traveled and the indicated remaining range are added together for the Edmunds Tested Range figure.',
                    }}
                    id="range-insight"
                    onTooltipOpen={onTooltipOpen}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  {epaRange ? (
                    <Fragment>{isPluginHybrid ? 'EPA Estimated Electric Range' : 'EPA Estimated Range'}</Fragment>
                  ) : (
                    <Fragment>
                      Estimated EV Range
                      <TooltipItem
                        className="top-0 small"
                        item={{
                          placement: 'bottom',
                          text:
                            'This vehicle’s electric range, which is how many miles it can drive on a single battery charge, has not been verified by the EPA. The estimate shown here is based on non-EPA sources.',
                        }}
                        id="range-insight"
                        onTooltipOpen={onTooltipOpen}
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          )}
          <div className="medium">
            {isPluginHybrid ? (
              <Fragment>The {modelName} will automatically switch to gas when the electric battery is low.</Fragment>
            ) : (
              <Fragment>
                EV batteries lose 1-2% of range per year.{' '}
                <Fragment>
                  {isUsed
                    ? `Est. range for this car is ${currentRange} miles.`
                    : `Est. range for this car is ${Math.round(currentRange * 0.85)} miles after 8 years.`}
                </Fragment>
              </Fragment>
            )}
            <TooltipItem
              className="top-0"
              item={{
                placement: 'bottom',
                text: (
                  <Fragment>
                    Electric cars typically experience 1-2% of range loss per year with slightly faster degradation over
                    the first 50,000 miles as the car settles into its long term state, according to{' '}
                    <Link
                      className="text-underline"
                      to="https://www.recurrentauto.com/research/lessons-in-electric-car-battery-health"
                      target="_blank"
                      rel="nofollow"
                    >
                      Recurrent
                    </Link>
                    &apos;s study of 15,000 EVs.
                  </Fragment>
                ),
              }}
              delay={{ show: 0, hide: 250 }}
              autohide={false}
              id="range-battery"
              onTooltipOpen={onTooltipOpen}
            />
          </div>
        </div>
      )}

      {!!currentRange && (
        <RangeInsightMap
          currentRange={currentRange}
          vehicleName={vehicleName}
          visitorLocation={visitorLocation}
          onTooltipOpen={onTooltipOpen}
          isTestedRange={!!testedRange}
        />
      )}
    </div>
  );
}

RangeInsight.propTypes = {
  vehicleName: PropTypes.string,
  modelName: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testedRange: PropTypes.number,
  epaRange: PropTypes.number,
  reviewEstimatedRange: PropTypes.number,
  visitorLocation: VisitorEntities.Location,
  onTooltipOpen: PropTypes.func,
  isPluginHybrid: PropTypes.bool,
  isUsed: PropTypes.bool,
};

RangeInsight.defaultProps = {
  vehicleName: null,
  modelName: null,
  year: null,
  testedRange: null,
  epaRange: null,
  reviewEstimatedRange: null,
  visitorLocation: null,
  onTooltipOpen: null,
  isPluginHybrid: false,
  isUsed: false,
};
