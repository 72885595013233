import { getCurrentYear } from 'site-modules/shared/utils/time-util';

export const RANGE_SOURCE = { TESTED: 'edmunds_tested', EPA: 'epa', ESTIMATED: 'estimated' };

export function getUsedYears({ year, isUsed }) {
  return isUsed ? getCurrentYear() - year : 0;
}

export function getUsedRange({ totalRange, year, isUsed }) {
  const usedYears = getUsedYears({ year, isUsed });

  return Math.round(totalRange * (1 - 0.01875 * usedYears));
}

export function getEvRangeSource({ isTested, isEpa, isEstimated }) {
  switch (true) {
    case isTested: {
      return RANGE_SOURCE.TESTED;
    }
    case isEpa: {
      return RANGE_SOURCE.EPA;
    }
    case isEstimated: {
      return RANGE_SOURCE.ESTIMATED;
    }
    default: {
      return null;
    }
  }
}
